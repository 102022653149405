
import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import Crypto from "crypto";

@Component({
  components: {FormWrapper}
})
export default class TakipAcVekaletEkleForm extends Mixins(SimpleInputMixin) {
  @Prop() vekaletId!: number;
  @Prop() file!: any;
  @Ref('form') form!: FormWrapper;
  localValue = {data: null as string | null, name: '', mimeType: '', dosya: null as any | null, hash: ''};

  async mounted() {
    this.localValue = {data: null, name: '', mimeType: '', dosya: null, hash: ''};
    this.input();
    if (this.vekaletId)
      await this.load();
  }

  async load() {
    this.localValue.dosya = await this.$helper.getBlobFromLink('/v1/file/download/' + this.file.file_path.replace('uploads/', ''));
    this.localValue.dosya.name = this.file.name;
    await this.onDosyaChange(this.localValue.dosya);
    this.form.setDefaultLocalValue();
  }

  fileRule(dosya: any, zorunluMu: boolean) {
    if (dosya) {
      let uzanti = dosya.name.split('.').pop();
      if (["udf", "pdf", "jpg", "jpeg", "png", "tif", "tiff", "UDF", "PDF", "JPG", "JPEG", "PNG", "TIF", "TIFF"].indexOf(uzanti) >= 0) {
        if (dosya.size < 10485760)
          return true;
        else
          return "Doküman boyutu 10MB'dan fazla olamaz.";
      } else
        return "Doküman formatı UYAP'a uygun değil."
    } else {
      if (zorunluMu)
        return 'Lütfen bu alanı doldurun.';
      else
        return true;
    }
  }

  async onDosyaChange(file: File) {
    this.localValue.data = "";
    if (file) {
      let base64File: any = await this.$helper.convertToBase64(file);
      this.localValue.hash = Crypto.createHash('sha1').update((base64File.split(','))[1]).digest().toString('hex');
      this.localValue.data = 'data:' + file.type + ';base64,' + (base64File.split(','))[1];
      this.localValue.name = file.name;
      this.localValue.mimeType = file.type;
    } else {
      this.localValue = {data: null, name: '', mimeType: '', dosya: null, hash: ''};
    }
    this.input();
  }
}
