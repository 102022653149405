
import {Component, Mixins, Prop} from 'vue-property-decorator';
import FormWrapper from "@/components/FormWrapper.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {MDB} from "@/plugins/uyap-plugin/MDB";
import {mask} from "vue-the-mask";

@Component({
  directives: {
    mask
  },
  components: {FormWrapper}
})
export default class UyapIlamBilgileriForm extends Mixins(ObjectInputMixin) {
  @Prop() tarafList!: any;
  @Prop() ilamSorgusuDurumu!: boolean;
  mdb = new MDB();
  ilamSorgulanacakIlamTurler = [0, 1, 2, '0', '1', '2'];
  ilamDosyaTurleri:any = [];
  yilMask = "####";
  siraMask = "######";
  ilamSorgulari:any = {
    dosyaTurleri: [],
    dosyaTurleriLoading: false,
    ilamTuru: "",
    yargiBirimleri: [],
    yargiBirimleriLoading: false,
    adliyeler: [],
    adliyelerLoading: false,
    mahkemeler: [],
    mahkemelerLoading: false,
    adliye: "",
    dosyaYil: "",
    dosyaSiraNo: "",
    kararYil: "",
    kararSiraNo: "",
    yevmiyeYil: "",
    yevmiyeSiraNo: "",
    dosyaTuru: "",
    yargiBirimi: "",
    il: "",
    mahkeme: null as string | null,
    ilamliKurumAdi: "",
    eslesmeDurumu: false
  }

  mounted() {
    this.ilamSorgulari.dosyaSiraNo = this.localValue.dosyaSira;
    this.ilamSorgulari.dosyaYil = this.localValue.dosyaYil;
    this.ilamSorgulari.kararSiraNo = this.localValue.kararDosyaSira;
    this.ilamSorgulari.kararYil = this.localValue.kararDosyaYil;
    this.ilamSorgulari.yevmiyeSiraNo = this.localValue.yevmiyeSira;
    this.ilamSorgulari.yevmiyeYil = this.localValue.yevmiyeYil;
    this.ilamSorgulari.ilamliKurumAdi = this.localValue.ilamliKurumAdi;
    this.ilamSorgulari.eslesmeDurumu = this.ilamSorgusuDurumu;
    if (this.localValue.dosyaTurKod)
      this.localValue.dosyaTurKod = Number(this.localValue.dosyaTurKod);
    if (this.localValue.ilamTuru) {
      this.dosyaTurleriLoad(this.localValue.ilamTuru);
      if (this.ilamSorgulanacakIlamTurler.includes(this.localValue.ilamTuru))
        this.yargiBirimleriLoad(this.localValue.ilamTuru);
    }
  }

  save() {
    this.$emit("ilamSorgu", this.ilamSorgulari.eslesmeDurumu);
  }

  async onIlamTuruChange(ilamTuruVal: any) {
    this.ilamSorgulari.ilamTuru = ilamTuruVal;
    this.localValue.ilamBilgileri.ilamTuru = ilamTuruVal;
    this.localValue.ilamTuru = ilamTuruVal;
    if (!(ilamTuruVal == '0' || ilamTuruVal == '1')) {
      this.ilamSorgulari.adliye = "";
    }
    this.input();
    await this.dosyaTurleriLoad(ilamTuruVal)
    if (this.ilamSorgulanacakIlamTurler.includes(ilamTuruVal)) {
      await this.yargiBirimleriLoad(ilamTuruVal);
    }
  }

  async dosyaTurleriLoad(ilamTuruVal: number) {
    try {
      this.ilamSorgulari.dosyaTurleriLoading = true;
      this.ilamSorgulari.dosyaTurleri = await this.$uyap.IcraTakipIlamDosyaTuru().run({ilamTuru: ilamTuruVal});
    } finally {
      this.ilamSorgulari.dosyaTurleriLoading = false;
    }
  }

  async yargiBirimleriLoad(ilamTuruVal: number | string) {
    let yargiTuru = this.mdb.ilamKurumTurleri.find((ilamYargiTuru) => (ilamYargiTuru.value == ilamTuruVal)).yargiTuru;
    try {
      this.ilamSorgulari.yargiBirimleriLoading = true;
      this.ilamSorgulari.yargiBirimleri = await this.$uyap.YargiBirimleri().run({yargiTuru: yargiTuru});
    } finally {
      this.ilamSorgulari.yargiBirimleriLoading = false;
    }
  }

  onIlamDosyaTurChange(value: number | string) {
    // @ts-ignore
    this.ilamSorgulari.dosyaTuru = value;
    this.localValue.dosyaTurKod = value;
    this.localValue.dosyaTurKodText = this.ilamDosyaTurleri.find((dosyaTuru: any) => dosyaTuru.value == value).name;
    this.input();
  }

  async loadIlamAdliyedekiTumMahkemeler() {
    try {
      this.ilamSorgulari.mahkemelerLoading = true;
      this.ilamSorgulari.mahkemeler = [];
      this.ilamSorgulari.mahkemeler = await this.$uyap.AdliyedekiTumMahkemeler().run({
        adliye: this.ilamSorgulari.adliye,
        bltr2: this.ilamSorgulari.yargiBirimi
      })
    } finally {
      this.ilamSorgulari.mahkemelerLoading = false;
    }
  }

  onIlamYargiBirimiChange(value: number | string) {
    this.ilamSorgulari.yargiBirimi = value;
    if (this.ilamSorgulari.adliye)
      this.loadIlamAdliyedekiTumMahkemeler();
    this.input();
  }

  onIlamAdliyeChange(value: number | string) {
    this.ilamSorgulari.adliye = value;
    if (this.ilamSorgulari.yargiBirimi)
      this.loadIlamAdliyedekiTumMahkemeler();
    this.input();
  }

  async onIlamIlChange(value: any) {
    this.ilamSorgulari.il = value;
    if (this.localValue.ilamTuru == 0 || this.localValue.ilamTuru == 1) {
      try {
        this.ilamSorgulari.adliyelerLoading = true;
        this.ilamSorgulari.adliyeler = await this.$uyap.IldekiAdliyeler().run({il: value});
        let adliyeExist= this.ilamSorgulari.adliyeler.some((item: any) => item.orgKodu==this.ilamSorgulari.adliye);
        if (adliyeExist && this.ilamSorgulari.adliye && this.ilamSorgulari.yargiBirimi)
          await this.loadIlamAdliyedekiTumMahkemeler();
      } finally {
        this.ilamSorgulari.mahkeme = null;
        this.localValue.birimId = null;
        this.localValue.birimAdi = null;
        this.ilamSorgulari.adliyelerLoading = false;
      }
    } else {
      try {
        this.ilamSorgulari.mahkemelerLoading = true;
        this.ilamSorgulari.mahkemeler = [];
        this.ilamSorgulari.mahkemeler = await this.$uyap.IldekiIdariBirimler().run({
          il: value,
          bltr2: this.localValue.ilamBilgileri.yargiBirimi,
          isAll: true
        });
      } finally {
        this.ilamSorgulari.mahkemelerLoading = false;
      }
    }
  }

  onIlamMahkemeChange(value: any) {
    this.ilamSorgulari.mahkeme = value;
    this.localValue.birimId = value;
    this.localValue.birimAdi = this.ilamSorgulari.mahkemeler.find((mahkeme:any) => mahkeme.birimId == value).birimAdi;
    this.input();
  }

  onIlamDosyaYilChange(value: any) {
    this.ilamSorgulari.dosyaYil = value;
    this.localValue.dosyaYil = value;
    this.localValue.dosyaNo = this.localValue.dosyaYil + '/' + this.localValue.dosyaSira;
    this.input();
  }

  onIlamDosyaSiraNoChange(value: any) {
    this.ilamSorgulari.dosyaSiraNo = value;
    this.localValue.dosyaSira = value;
    this.localValue.dosyaNo = this.localValue.dosyaYil + '/' + this.localValue.dosyaSira;
    this.input();
  }

  onIlamKararYilChange(value: any) {
    this.ilamSorgulari.kararYil = value;
    this.localValue.kararDosyaYil = value;
    this.input();
  }

  onIlamKararSiraNoChange(value: any) {
    this.ilamSorgulari.kararSiraNo = value;
    this.localValue.kararDosyaSira = value;
    this.input();
  }

  onIlamYevmiyeYilChange(value: any) {
    this.ilamSorgulari.yevmiyeYil = value;
    this.localValue.yevmiyeYil = value;
    this.input();
  }

  onIlamYevmiyeSiraNoChange(value: any) {
    this.ilamSorgulari.yevmiyeSiraNo = value;
    this.localValue.yevmiyeSira = value;
    this.input();
  }

  onKurumAdiChange(value: string) {
    this.ilamSorgulari.ilamliKurumAdi = value;
    this.localValue.ilamliKurumAdi = value;
    this.input();
  }

  ilamBilgileriniKontrolEt() {
    this.$uyap.IcraTakipIlamDosyasiUygunMu().run({
      birimId: this.localValue.birimId,
      ilamTuru: this.localValue.ilamTuru,
      dosyaTurKod: this.localValue.dosyaTurKod,
      dosyaYil: this.localValue.dosyaYil,
      dosyaSiraNo: this.localValue.dosyaSira,
      dosyaNo: this.localValue.dosyaYil + "/" + this.localValue.dosyaSira,
      TarafList: JSON.stringify(this.tarafList)
    }).then(() => {
      this.ilamSorgulari.eslesmeDurumu = true;
    }).catch(() => {
      this.ilamSorgulari.eslesmeDurumu = false;
    })
  }
}
